import { Translations } from './types'

const en: Translations = {
  chat: {
    brandNotFound: 'Failed to get brand information',
    alreadyReceived: 'You have already received the product',
    receivedQrError: 'Failed to get the received QR code',
    messages: {
      error: {
        text: 'Payment has not yet been completed.',
      },
      brandName: {
        text: 'Here are the brands you are looking for!\nWe hope you find one of your favorites.',
      },
      brand: {
        text: 'Check the item list of this brand',
        buttonTitle: 'Click here for list',
      },
      completePayment: {
        text: 'After completing the payment at the checkout counter, click the button below. You will be informed of the product pickup time.',
        buttonTitle: 'Settlement Complete',
      },
      downloadReceipt: {
        title: 'Do you use receipts?',
        text: 'You can download receipt data (PDF) to your device. Please note that if you do not wish to issue a receipt, you will not be able to return or exchange the items.',
        note: 'Please note that once you close this screen, you will not be able to reissue a receipt.',
        buttonTitle: 'Download Receipt(PDF)',
      },
      fixed: {
        hello: {
          text: "Hello, I’m ChooseBuddy.\nThis is CHOOSEBASE SHIBUYA chat support.\nAll payments at this store are cashless, so you can enjoy shopping without using cash.\n\nTo view a brand's products, scan the brand's QR code from the “Scan” menu below.",
        },
        scan: {
          text: 'To browse other brands\' products, please scan the brand\'s QR code from the "Scan" menu below.',
        },
        readyToBuy: {
          title: 'Ready to buy!',
          text: 'Your purchase of the product has been completed.\nPlease present this form to the checkout counter,and complete the payment procedure.',
        },
      },
      paymentQr: {
        expirationDate: 'Expiration date',
      },
      receivedQr: {
        title: 'Thank you for your purchase!',
        text: 'Payment has been completed.\nPlease wait a moment for us to prepare your order.',
        receivingTime: 'Receiving time of goods',
        receivingText:
          'Please come to the checkout counter again at the above time.\nWe will give you the items you ordered.',
      },
    },
  },
  taxIn: 'Tax In',
  common: {
    exhibitionArea: 'Exhibition Area',
  },
  onboarding: {
    title: 'Shopping Guide',
    step1: 'Choose items\nwith your smartphone',
    step2: 'Cashless payment at the cash register',
    step3: 'Receive on the spot',
    start: 'Start Shopping',
    backToShopping: 'Back to Shopping',
    recommendations:
      'This site is best viewed with a web browser such as Safari or Chrome.',
  },
  checkoutGuide: {
    headline: 'Once you have decided on an item, \ngo to the cashier',
    text: 'Did you find a product you like?\nOnce you have decided on what you want, please proceed to the checkout counter to make your payment.',
  },
  cart: {
    includeNotInCatalog:
      'Products that are no longer listed in the catalog are included.',
    deleteItemNotInCatalog: 'Please remove them from your cart.',
    failedToGenerateQr:
      'QR Code generation failed. Please try again in a location with good communication conditions. ',
    failedToChangeAmount:
      'Quantity exceeds available stock. Failed to update cart.',
    failedToChangeReceivedPlace: 'Failed to change received place',
    failedToChangeWrappingType: 'Failed to change wrapping type',
    agreeToTermsOfUseAndPrivacyPolicy:
      'Agree to {termsOfUse} and {privacyPolicy}',
    termsOfUse: 'Terms of Use',
    privacyPolicy: 'Privacy Policy',
    backTo: 'Back to Chat',
    shoppingBag: 'Shopping bag',
    guideText:
      'Select how you would like to receive the product,\nPayment QR code will be issued.',
    guideCaution: 'The inventory arrangements have not yet been completed.',
    wrappingMessage: 'I would like gift wrapping',
    wrappingPrice: 'Wrapping fee',
    wrappingCaution: 'We will ask for details at the register.',
    subTotal: 'subtotal',
    gift: 'gift',
    total: 'Total',
    paymentButton: 'Proceed to payment procedure',
    paymentNotice:
      'Our store accepts cashless payment.\nJust present the QR code at the checkout counter,\nYou can make a quick payment by presenting \nthe QR code at the checkout counter.',
    invalidCouponTitle: 'The coupon selected is invalid and could not be used.',
    invalidCouponText: 'Please cancel the use of the coupon.',
    noProductsTitle: 'Could not issue a payment code.',
    noProductsText: 'There are no products available for stock.',
    qrCodeTitle: 'Payment code issued.',
    qrCodeTextLine:
      'We have sent a message to your authenticated LINE account. If you have not received it, please check that you have not blocked this account.',
    qrCodeTextEmail:
      'We are sending a QR code for payment to {email}. If you have not received the email, please set your notification settings again.',
    remove: 'Remove',
    cancel: 'Cancel',
    confirmToRemoveProduct: 'Remove the product from the bag.\nAre you sure?',
    nothingInBag: 'Nothing in the bag yet.',
    nothingInBagText:
      "The right product for you from the area you are in.\nLet's go find the right product for you from the area you are in!",
    noStock: 'Stock not available',
    fewStock: 'Stock not available',
    priceChanged: 'Some items have changed in price',
    confirmMessage: 'Check the message',
    cartChanged: 'We are sorry.\nThere is a change in the shopping bag.',
    close: 'Close',
  },
  productDetail: {
    lowStock: 'Low Stock',
    more: 'See more details',
    less: 'Close Details',
    addToCart: 'Put in your shopping bag',
    soldOut: 'Sold Out',
    addedToCart: 'Added item to bag',
    failedToAddToCart: 'Could not reserve item',
  },
  aiCatalog: {
    scanQRCode: 'Scan the QRcode near the item.',
  },
}

export default en
