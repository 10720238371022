import en from './locales/en'
import ja from './locales/ja'
import ko from './locales/ko'
import zh from './locales/zh'

export default defineI18nConfig(() => ({
  legacy: false,
  fallbackLocale: 'en',
  messages: { en, ja, ko, zh },
}))
